<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="退货单号" >
                <el-input v-model="search.tuiHuoDH" size="small" />
            </x-search-item>
            <x-search-item label="退货时间" textWidth="400px">
                <x-date-between separator="~" :start.sync="search.tuiHuoSJBegin" :end.sync="search.tuiHuoSJEnd"
                                type="datetimerange" size="small"/>
            </x-search-item>
            <x-search-item label="供货商" >
                <el-input v-model="search.gongHuoShang" size="small" />
            </x-search-item>
            <x-search-item label="收款状态" >
                <x-selector-one v-model="search.shouKuanZT" size="small" dictType="T_COLLECTION_STATUS"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
                <excel-export :tableData="exportTableData" :headAndKey="headAndKey" :isSum="true" :condition="condition"
                              fileName="退货应收款导出">
                    <el-button type="success" size="mini" style="margin-left: 10px">导出</el-button>
                </excel-export>
            </div>
        </div>
        <div>
            <el-table :data="tongJiTable" :header-cell-style="{'text-align':'center'}">
                <el-table-column  prop="yingShouJinE" label="应收金额" align="center"/>
                <el-table-column  prop="yiShouJinE" label="已收金额" align="center"/>
                <el-table-column  prop="daiShouJinE" label="待收金额" align="center"/>
            </el-table>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                @selection-change="handleSelectionChange">
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="gongHuoShang" label="供货商" />
            <el-table-column width="180" prop="tuiHuoDH" label="退货单号" />
            <el-table-column width="180" prop="caoZuoRen" label="操作人" />
            <el-table-column width="180" prop="tuiHuoSJ" label="退货时间" />
            <el-table-column width="180" prop="yingShouJinE" label="应收金额" />
            <el-table-column width="180" prop="yiShouJinE" label="已收金额" />
            <el-table-column width="180" prop="daiShouJinE" label="待收金额" />
            <el-table-column width="180" label="收款状态">
                <x-dict-show slot-scope="{row}" :code="row.shouKuanZT" dictType="T_COLLECTION_STATUS" />
            </el-table-column>
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button v-if="siYiShouKuan(row.shouKuanZT)" type="danger" size="mini" round  @click="handleUpdate(row.id)" icon="el-icon-coin">收款</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>

    </div>
</template>
<script>
    import * as service from "@/service/zjgl/TuiHuoYingShouKuan";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/zjgl/thysgl/TuiHuoYingShouKuanEdit";
    import {DICT_TYPE_COLLECTION_STATUS} from "@/util/constant";
    import {deepCopy} from "@/util/objects";

    export default {
        name: "TuiHuoYingShouKuanList",
        mixins: [XTableBase],
        components: {Edit},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    gongHuoShang: '',
                    tuiHuoDH: '',
                    shouKuanZT: '',
                    tuiHuoSJBegin:"",
                    tuiHuoSJEnd:"",
                },
                headAndKey: {
                    gongHuoShang: "供货商",
                    tuiHuoDH: "退货单号",
                    caoZuoRen: "操作人",
                    tuiHuoSJ: "退货时间",
                    yingShouJinE: "应收金额",
                    yiShouJinE: "已收金额",
                    daiShouJinE: "待收金额",
                    shouKuanZT: "收款状态",
                },
                exportTableData:[],
                condition: [],
                tongJiTable:[],
                titleTabelHeight:100
            }
        },
        computed:{
            siYiShouKuan(){
                return (shouKuanZT)=>{
                    return DICT_TYPE_COLLECTION_STATUS.YSK.key!==shouKuanZT
                }
            }
        },
        methods:{
            refresh() { //默认刷新列表方法
                this.table.loading = true;
                this.service.listTongJi(this.search).then((res) =>{
                    this.tongJiTable = []
                    this.tongJiTable.push(res.data)
                })
                return this.refreshService(Object.assign(this.search, this.searchNoClear)).then((response) => {
                    let ret = response.data;
                    this.table.data = ret.records;
                    this.table.total = ret.total || 0;
                    if(this.table.data && this.table.data.length>0){
                        this.exportTableData = deepCopy(this.table.data)
                        this.exportTableData.forEach(item=>{
                            if(item.shouKuanZT===DICT_TYPE_COLLECTION_STATUS.YSK.key){
                                item.shouKuanZT=DICT_TYPE_COLLECTION_STATUS.YSK.name
                            }else if(item.shouKuanZT===DICT_TYPE_COLLECTION_STATUS.DSK.key){
                                item.shouKuanZT=DICT_TYPE_COLLECTION_STATUS.DSK.name
                            }else{
                                item.shouKuanZT=DICT_TYPE_COLLECTION_STATUS.BFSK.name
                            }
                        })
                    }
                    //处理11条数据删除一条不显示问题
                    if (this.searchNoClear.current !== 1 && (!ret.records || ret.records === 0)) {
                        this.searchNoClear.current--
                        this.refresh()
                        return
                    }
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
        }
    }
</script>

<style scoped>
</style>
