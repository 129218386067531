<template>
    <el-dialog :title="shiJiTitle" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="700px">
        <div class="formTitle">
            <div style="width: 50%;font-size: 30px;color: white">待收金额：{{yingShouKuanObj.daiShouJinE}}</div>
            <div style="width: 50%">
                <div class="rightTitle">
                    <span style="font-size: 20px">应收金额</span>
                    <span style="font-size: 20px">{{yingShouKuanObj.yingShouJinE}}</span>
                </div>
                <div class="rightTitle">
                    <span style="font-size: 20px">已收金额</span>
                    <span style="font-size: 20px">{{yingShouKuanObj.yiShouJinE}}</span>
                </div>
            </div>
        </div>
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="收款方式"  prop="shouKuanFS" class="line">
                <x-selector-one v-model="form.shouKuanFS" size="small" dictType="T_COLLECTION"/>
            </el-form-item>
            <el-form-item v-if="isYhkSk" label="付款方卡号"  prop="yinXingKaHao" class="line">
                <el-input v-model="form.yinXingKaHao" size="small" disabled></el-input>
            </el-form-item>
            <el-form-item label="收款金额"  prop="jinE" class="line">
                <el-input v-model="form.jinE" size="small"></el-input>
            </el-form-item>
            <el-form-item label="付款时间" prop="shouKuanSJ" class="line">
                <el-date-picker v-model="form.shouKuanSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="备注"  prop="beiZhu" class="line">
                <el-input type="textarea" v-model="form.beiZhu" ></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {getUpdate} from "@/service/zjgl/TuiHuoYingShouKuan";
    import {add} from "@/service/zjgl/TuiHuoZiJinLiuShui";
    import {deepCopy, deepMerge} from "@/util/objects"
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import {DICT_TYPE_COLLECTION} from "@/util/constant";

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required,positiveEightTwo} = ruleBuilder;
            this.rules = {
                jinE: [required('string', ['blur', 'change']),positiveEightTwo()], // 支付金额
                shouKuanSJ:[required('string', ['blur', 'change'])],
            }
            this.titlePrefix = '收款记录';
            this.defaultForm = {
                id: null,
                tuiHuoDH:"",//退货单号
                keHuMC:'',//客户名称
                jinE:"",//金额
                shouKuanFS:DICT_TYPE_COLLECTION.WX.key,//收款方式
                yinXingKaHao:"",//银行卡号
                kaiHuYinHang:"",//开户银行
                beiZhu:"",//备注
                shouKuanSJ:"",//收款时间
            };
            this.addService = add;
        },
        data(){
            return {
                shiJiTitle: "收款记录",
                yingShouKuanObj:{daiShouJinE:0,yingShouJinE:0,yiShouJinE:0}
            }
        },
        computed:{
            isYhkSk(){
                return DICT_TYPE_COLLECTION.YHK.key===this.form.shouKuanFS
            }
        },
        methods:{
            open() {
                this.form=deepCopy(this.defaultForm)
                return getUpdate(this.editor.id).then((response) => {
                    this.yingShouKuanObj = response.data
                    this.form.keHuMC = this.yingShouKuanObj.gongHuoShang
                    this.form.tuiHuoDH = this.yingShouKuanObj.tuiHuoDH
                    this.form.yinXingKaHao = this.yingShouKuanObj.yinXingKaHao
                    this.form.kaiHuYinHang = this.yingShouKuanObj.kaiHuYinHang
                    //清空校验
                    this._clearValidate();
                    return response;
                })
            },
            async save(callback) { // 通用save方法 增加回调
                this.loading = true;
                let isvalidate = false;
                try {
                    await this.$refs.form.validate((valid, message) => {
                        if (valid) { // 如果校验通过则提交
                            isvalidate = true;
                        } else { // 校验不通过
                            this.$message.error("有必填项没有填写请检查！");
                            this.loading = false;
                        }
                    });
                } catch (e) {
                    this.loading = false;
                    return;
                }
                if (!isvalidate) return;
                if(this.form.jinE>this.yingShouKuanObj.daiShouJinE){
                    this.$message.warning("收款金额不可大于待收款金额！");
                    this.loading = false;
                    return ;
                }
                if(this.form.jinE===0){
                    this.$message.warning("收款金额不能为0！");
                    this.loading = false;
                    return ;
                }
                let api = this.addService;
                const response = await api(this.form).finally(() => {
                    this.loading = false;
                });//执行保存方法
                if (callback && typeof callback === 'function') {
                    callback(response);
                }
                //打印提示信息
                this.$message.success(response.msg);
                this.$emit("submitted", response);
                this.cancel();
            },
        }
    }
</script>

<style scoped>
    .formTitle{
        box-sizing: border-box;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        background: rgb(255, 117, 87);
        margin-bottom: 10px;
    }
    .rightTitle{
        display: flex;
        justify-content: space-between;
        height: 30px;
        line-height: 30px;
        font-size: 20px;
        color: white;
    }
</style>
